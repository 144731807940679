import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Advent of code`}</h1>
    <p>{`I recorded myself solving the `}<a parentName="p" {...{
        "href": "https://adventofcode.com/"
      }}>{`advent of code`}</a>{` challenges.`}</p>
    <p>{`It has 94% on `}<a parentName="p" {...{
        "href": "https://www.rottentomatoes.com/search?search=Yann%20solving%20advent%20of%20code"
      }}>{`rotten tomatoes`}</a>{`.`}</p>
    <p>{`Play list `}<a parentName="p" {...{
        "href": "https://youtube.com/playlist?list=PLUMSP-uVBbm_dNABlQWX72Biu2iVCYewh"
      }}>{`here`}</a>{` (it's a work in progress. still figuring out if it's worth doing and how best to do it)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      